import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="0">
                <h2 className="title">Pozycjonowanie stron internetowych - SEO</h2>
                <h5 className="description">
                Witaj!
                Niniejsza strona ma za zadanie wyjaśnic kilka podstawowych pojęć związanych 
                z pozycjonowaniem stron internetowych .Mimo wielu dostępnych i szeroko 
                komentowanych opracowań zwykłemu użytkownikowi internetu niewiele mówią takie 
                pojęcia jak SEO czy Page Rank .Zajmuję się zawodowo pozycjonowaniem stron 
                od kilku lat, a mimo wszystko w dalszym ciągu jestem zaskakiwany niektórymi 
                informacjami związanymi z pozycjonowaniem. Serdecznie zapraszam do zapoznania 
                się z przygotowanymi przeze mnie materiałami. Z oczywistych względów oferuję
                również usługi związane nie tylko z pozycjonowaniem ale również pełną obsługę 
                informatyczną firm i osób fizycznych. Więcej informacji na ten temat w poszczególnych 
                działach.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/login.jpg") + ")",
                    }}
                  >
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg3.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    O pozycjonowaniu słów pare
                  </h3>
                  <h5>
                  Czym jest pozycjonowanie stron i jak może Ci pomóc?
                  </h5>
                  <p>
                  Krótka, sucha definicja pozycjonowania za Wikipedią:
                  Pozycjonowanie stron (ang. Search engine optimization - SEO, Web Positioning) 
                  to działania zmierzające do wypromowania danego serwisu internetowego na jak 
                  najwyższe pozycje w wynikach wyszukiwania wyszukiwarek internetowych dla wybranych 
                  wyrażeń kluczowych.Oznacza to ni mniej, ni więcej tyle, że istotnymi z punktu widzenia 
                  szukającego jest tylko kilka pierwszych wyników, wyświetlonych przez wyszukiwarkę 
                  po wpisaniu szukanych słów. A pozycjoner (nieładne słowo ale w języku polskim nie 
                  ma dobrego zamiennika dla specjalistów SEO) ma za zadanie spowodować, że to właśnie 
                  Twoja strona znajdzie się na pierwszym miejscu.
                  </p>
                  <h5>
                  Dlaczego powinno Ci na tym zależeć ?
                  </h5>
                  <p>
                  Z własnego doświadczenia mogę powiedzieć (i Ty drogi internauto zapewne również), 
                  że przy wyszukiwaniu interesuje nas tylko kilka pierwszych wyników, w zasadzie tylko 
                  pierwsza strona (10 wyników) jest przez szukającego zauważona. Powoduje to określone 
                  implikacje dla firm i osób, które za pośrednictwem internetu starają się zdobyć klienta 
                  bądź po prostu chcą zaistnieć w sieci. Wziąwszy pod uwagę to, że nie każde kliknięcie w 
                  link prowadzący do Twojej strony wygeneruje Ci klienta i co za tym idzie dochód, trzeba 
                  wielu odwiedzających, żeby koszt wykonania i obsługi strony się zwrócił. Działa tu 
                  marketingowa "zasada lejka". Im więcej klientów "wpadnie" na Twoją stronę - nawet 
                  niekoniecznie zainteresowanych dokładnie tą treścią - tym większe prawdopodopieństwo, 
                  że niektórzy zostaną Twoimi klientami. I dalej tylko od Ciebie zależy jak ich obsłużysz 
                  i czy spowodujesz, że zostaną przy Tobie . Ale tu już wchodzimy w marketing i obsługę 
                  klienta a to nie to jest clou tej strony.
                  </p>
                  <h5>
                  Wybieranie fraz do pozycjonowania ?
                  </h5>
                  <p>
                  Drugą, ale nie mniej istotną sprawą jest sensowne celowanie w klienta. 
                  Kluczowym tu elementem jest wybranie fraz do pozycjonowania. Najprostsze 
                  możliwe wyjaśnienie tego to przykład:
                  - produkujemy komputery i chcemy na tym zarobić więc musimy oferować nasz 
                  towar klientom którzy chcą komputer kupić, po co więc w takim razie pozycjonować 
                  stronę pod kątem fraz "karma dla zwierząt" - osoba która wejdzie na naszą stronę 
                  szukając karmy dla zwierząt na pewno nie nabędzie komputera - wręcz przeciwnie będzie 
                  strasznie zawiedziona i zdenerwowana naszą próba oszustwa (nawiasem mówiąc niektórzy 
                  pozycjonując swoje strony stosują podobne sztuczki - nazywamy to "Black Hat SEO") i z 
                  całą pewnością nawet nie zechce zapoznać się z naszą ofertą, natomiast strona tegoż 
                  producenta komputerów wypozycjonowana na fraz "producent komputerów" powoduje zwiekszone 
                  zainteresowanie stroną ludzi szukających takiego producenta Z powyższego wynika 
                  jednoznacznie, że zdefiniowanie fraz do pozycjonowania jest bardzo istotne. Istotne jest 
                  również to, że tak naprawdę skuteczne może być pozycjonowanie strony tylko pod kątem 2-3 
                  fraz. Co ważne dla wyszukiwarek frazy "producent komputera" i "producent komputerów" to 
                  zupełnie inne frazy (no może niezupełnie ale wyniki dla tych fraz są inne . Uff - jak widać 
                  przed przystąpieniem do pozycjonowania trzeba się mocno zastanowić co chcemy osiągnąć, i do 
                  jakiej grupy internautów trafić.
                  </p>
                 <h5> 
                  Co robi pozycjoner i dlaczego to tyle kosztuje ?
                  </h5>
                  <p>
                  Niestety, z różnych względów, algorytmy wyszukiwarek nie są jawne. Dlatego specjaliści 
                  SEO muszą się domyślać (na podstawie badań i doświadczeń) co jest najistotniejsze dla 
                  silników wyszukiwarek i co powoduje wysoki wynik w wynikach wyszukiwania. Dawno temu w 
                  zamierzchłych czasach najważniesze były słowa kluczowe opisujące stronę. Ze względu na 
                  łatwe oszukiwanie, algorytmy oceniające zrobiły się dużo bardziej skomplikowane i w tej 
                  chwili oceniają wiele elementów tworzących www. Między innymi zawartość słów i fraz w treści
                  strony, ilość linków i "anchor text" dla tego linka, linki "seo friendly", atrybuty 
                  znaczników "alt" czy "title" na stronie. Jest jeszcze zapewne kilka elementów wartości 
                  strony ale te powyżej wydają się aktualnie byc najważniejsze. Cała robota pozycjonerska 
                  skupia się wokół zapewnienia skondensowanej treści strony wokół fraz kluczowych oraz 
                  zapewnieniu sporej ilości wartościowych linków z odpowiednim "anchor textem" prowadzących 
                  do strony. Stosuje się do tego ręczne dodawanie do katalogów, automaty dodające czy , 
                  bardzo popularne, systemy linkujące. Z własnego doświadczenia mogę powiedzieć, że systemy 
                  linkujące są skuteczne pod warunkiem, że sama wartość strony jest stosunkowo wysoka. 
                  Do dobrego pozycjonowania strony potrzebny jest poza pomysłem - czas, czas i jeszcze 
                  raz czas. Pośpieszne dodawanie linków może doprowadzić tylko do zbanowania strony przez 
                  engine wyszukiwarki. Tutaj doświadczenie pozycjonera jest nie do przecenienia.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Oferta</h2>
            <div className="team">
              <Row>
                <Col md="0">
                  <div className="description">
                    <h4> 
                    Czujesz się zagubiony w dziedzinie informatyki?
                    Nie wiesz do czego służy sprzęt nazywamy jakimiś dziwnymi określeniami?
                    Potrzebujesz porady w kwestii trapiących Cię problemów?
                    </h4>
                    <p>
                    Napisz do mnie - nic Cię to nie kosztuje a być może będę Ci w stanie pomóc.
                    W końcu od tego jestem.Tworzę i obsługuję strony www. Programuję w PHP i C++, C#, 
                    javascript. Zajmuję się pozycjonowaniem i doradzam w zakresie marketingu komputerowego 
                    oraz pełnej obsługi informatycznej. Doradzam w zakresie sprzętu komputerowego 
                    (dostosowanie do potrzeb i możliwości).
                    Dla zainteresowanych oferuję sprzedaż sprzętu komputerowego, oprogramowania oraz
                    wszelkiej maści peryferiów czy materiałów eksploatacyjnych. 
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Kontakt</h2>
            <h5 className="description">Firma CEZ Cezary Żurawski zajmuje się pozycjonowaniem stron
             internetowych oraz profesjonalna obsługą informatyczną firm i osób prywatnych.</h5>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <p>
                Strona www.pozycjonowanie.se prezentuje treści dotyczące pozycjonowania stron 
                internetowych, wiedzy o tym zagadnieniu i specyfiki pozycjonowania.Aby się 
                skontaktować ze mną elektronicznie 
                </p>
                <h6>
                podaję adres emailowy: czarek@pozycjonowanie.se 
                </h6>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}
export default LandingPage;